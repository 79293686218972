import Image from "next/image";
import Link from "next/link";
import { useContext } from "react";
import AppContext from "../../contexts/appContext/appContext";
import { useRouter } from "next/router";

const navigation = {
  legal: [
    { name: "Blog", href: "https://blog.notion-widgets.com", beacon: "" },
    { name: "What's new", href: "#", beacon: "64bff6baf7e57563daff81af" },
    { name: "Affiliates — Earn up to $50 per sale", href: "https://tally.so/r/mRJlOv", beacon: "", newTab: true },
    { name: "Submit Feature Request", href: "", featurebase: true },
    { name: "Advertise with us", href: "https://tally.so/r/wzerp1", beacon: "", newTab: true },
  ],
  social: [
    {
      name: "Twitter",
      href: "https://twitter.com/notionwidgets",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
      name: "YouTube",
      href: "#",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
};

export default function Footer() {
  const currentYear = new Date().getFullYear();
  const { globalData } = useContext(AppContext);
  const {
    profile: {
      data: { plan },
    },
  } = globalData;
  const router = useRouter();

  return (
    <footer
      className="tw-bg-gradient-to-r tw-from-[#290b61] tw-via-50% tw-to-[#120231] tw-mt-12"
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="tw-sr-only">
        Footer
      </h2>
      <div className="tw-mx-auto tw-max-w-7xl md:tw-px-32 tw-px-12 tw-pb-0 tw-pt-16">
        <div className="md:tw-flex tw-justify-between tw-grid tw-rows">
          <div className="tw-space-y-8">
            <div className="tw-flex tw-flex-shrink-0 tw-items-center">
              <Link className="tw-text-white tw-text-xl tw-font-semibold tw-contents hover:tw-text-white" href="/">
                <Image className="tw-mr-4" src={"/whiteLogo.svg"} width={40} height={40} alt="notion widgets logo" />
                Notion Widget
              </Link>
            </div>
            <p className="tw-text-sm tw-leading-6 tw-text-gray-300 tw-max-w-[460px] tw-text-[15px]">
              Widgets made with ❤ by Notion Widgets. If you have any questions, please email us at{" "}
              <a
                className="tw-underline tw-text-gray-300 hover:tw-text-gray-300 tw-cursor-pointer"
                href="mailto:support@notion-widgets.com"
              >
                support@notion-widgets.com
              </a>
              .
            </p>
            <div className="tw-flex tw-space-x-6">
              {navigation.social.map((item) => (
                <a key={item.name} href={item.href} className="tw-text-white hover:tw-text-gray-400">
                  <span className="tw-sr-only">{item.name}</span>
                  <item.icon className="tw-h-10 tw-w-10" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
          <div className="tw-mt-4 md:tw-mt-0">
            <div className="tw-mt-10 md:tw-mt-0">
              <button
                type="button"
                className="tw-relative tw-inline-flex tw-items-center tw-gap-x-1.5 tw-rounded-md tw-px-3 tw-py-2 tw-text-sm tw-font-medium tw-text-white tw-shadow-sm tw-bg-[#553040]"
                style={{ border: "none" }}
                onClick={() => router.push("/pricing")}
              >
                <img className="" src={"/crown 1.svg"} alt="user pic" width={19} height={19} />
                {plan && plan !== "Free" ? plan : "Upgrade"}
              </button>
              <ul role="list" className="tw-mt-6 tw-space-y-4 tw-pl-0">
                {navigation.legal.map((item) => (
                  <li key={item.name} className="tw-list-none">
                    {!item.featurebase ? (
                      <a
                        href={item.href}
                        data-beacon-article={item.beacon}
                        target={item.newTab ? "_blank" : "_self"}
                        className="tw-text-sm tw-no-underline tw-leading-6 tw-text-gray-300 hover:tw-text-white"
                      >
                        {item.name}
                      </a>
                    ) : (
                      <a
                        data-featurebase-link
                        href="https://feedback.notion-widgets.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="tw-text-sm tw-no-underline tw-leading-6 tw-text-gray-300 hover:tw-text-white"
                      >
                        {item.name}
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="tw-flex tw-justify-center tw-pt-8">
          <p className="tw-text-sm tw-font-normal tw-leading-5 tw-text-purple-200 tw-mb-[4px]">
            <Link
              className="tw-underline tw-text-purple-200 hover:tw-text-white tw-cursor-pointer"
              href="/privacy-policy"
            >
              Privacy Policy
            </Link>{" "}
            &nbsp;
            <Link
              className="tw-underline tw-text-purple-200 hover:tw-text-white tw-cursor-pointer"
              href="/terms-conditions"
            >
              Terms and Conditions
            </Link>
          </p>
        </div>
        <div className="tw-flex tw-justify-center tw-h-">
          <p className="tw-text-xs tw-font-normal tw-leading-5 tw-text-purple-200 tw-mb-[8px]">
            &copy; {currentYear}: &nbsp;
            <a
              className="tw-underline tw-text-purple-200 hover:tw-text-white tw-cursor-pointer"
              href="https://www.notion-widgets.com/"
            >
              Notion Widgets
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}
